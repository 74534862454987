<template>
  <div id="shop-page">
    <ComingSoon v-if="!showShop" />
    <div v-else >
      <!-- SEARCH RESULT -->
      <div>
        <div class="w-full flex md:hidden h-auto mb-4" :class="`${this.$store.state.eCommerce.showAlertHeader ? '' : 'mt-0'}`" style="margin-top: 63px;">
          <side-bar-filters :categoryDetails="categoryDetails" :jsonCategoryName="jsonCategoryName" :brands="brands" :category="jsonCategories" :subCategories="subCategories" :onFilterClick="onFilterClick" :onCategoryClick="onCategoryClick" />
          <!-- <vs-select autocomplete class=" w-full" v-model="selectedSortBy">
    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in sortBy" />
  </vs-select> -->
        </div>
        <div v-if="selectedFilters.length > 0" class="block md:hidden my-4">
          Selected Filters{{`(${this.selectedFilters.length})`}}
        </div>
        <vs-breadcrumb
          class="block md:hidden"
          :items="populateBreadcrumbs"
          separator="chevron_right"
        ></vs-breadcrumb>

        <div class="font-medium text-black text-3xl">
          Shop
          {{
            jsonCategoryName && jsonCategoryName.length > 0
              ? jsonCategoryName
              : categoryDetails.name
          }}
        </div>

        <vs-breadcrumb
          class="hidden md:block mb-10"
          :items="populateBreadcrumbs"
          separator="chevron_right"
        ></vs-breadcrumb>
      </div>
<!-- Banner -->

      <div v-if="categoryDetails.desktopImage || jsonCategoryDesktopImg && jsonCategoryDesktopImg.length > 0" class=" w-full text-center hidden items-center sm:flex md:mt-16">
        <div class=" w-full text-center ">
          <img :src="jsonCategoryDesktopImg && jsonCategoryDesktopImg.length > 0
                  ? jsonCategoryDesktopImg
                  :   categoryDetails.desktopImage" class=" max-w-full"/>
        </div>
      </div>

      <div v-if="categoryDetails.mobileImage || jsonCategoryMobileImg && jsonCategoryMobileImg.length > 0" class=" w-full text-center flex items-center sm:hidden md:mt-16">
        <div class=" w-full text-center max-w-full p-5">
          <img :src="jsonCategoryMobileImg && jsonCategoryMobileImg.length > 0
                  ? jsonCategoryMobileImg
                  : categoryDetails.mobileImage" class=" max-w-full"/>
        </div>
      </div>

      <!-- Category Description -->
      <div class="mt-6 mb-10">
        <div class=" category-text w-full text-left"
          v-html="jsonCategoryDescription && jsonCategoryDescription.length > 0
                  ? jsonCategoryDescription
                  :  categoryDetails.productDescription" />
      </div>

      <div class="flex"  >
        <div v-if="jsonCategories.length > 0 || brands.length > 0" class="md:block hidden pr-8" style="width: 300px;"  >
          <div v-if="jsonCategories.length > 0" class="text-2xl text-black font-semibold mb-2">Categories</div>
          <div :key="item._id" v-for="(item) in jsonCategories" class="text-lg font-normal my-2 overflow-auto cursor-pointer">
            <a class="hover:underline text-lg font-normal my-2" @click="onCategoryClick(item.slug)">{{ item.name }}</a>
          </div>
          <div v-if="brands.length > 0" class="text-2xl text-black font-semibold">Brands</div>
          <div
            :key="item._id"
            v-for="(item, index) in brands"
          >
            <vs-checkbox
              class="ml-0 text-lg font-normal my-2"
              :value="item.isSelected"
              @click="onFilterClick(item, index, 'brand')"
              >{{ item.brandName }}</vs-checkbox
            >
          </div>
        </div>
        <div class=" w-screen">
<div class="flex items-center justify-between mb-5 text-xl">
  <div v-if="filteredItems.length > 0" class="hidden md:flex gap-3">
    {{filteredItems.length}}
    <div v-if="filteredItems.length > 1">
      products
    </div>
    <div v-else>
      product
    </div>
  </div>
  <!-- <div class="hidden md:flex items-center">
  Sort by
  <vs-select autocomplete  class="ml-3" v-model="selectedSortBy">
    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in sortBy" />
  </vs-select>
  </div> -->
</div>
    <div>
      <div v-if="paginatedItems.length > 0" class="grid grid-cols-2 lg:grid-cols-4 lg:gap-4 items-wrapper pt-1">
        <div v-for="(item, index) in paginatedItems" :key="item.objectID">
            <item-list-view v-if="paginatedItems.length > 0" :item="item" :index="index" :inCart="isInCartTest(item._id) && !isProductScript(item) && !isProductBundle(item)" @quantityChange="quantityChange"
                :superAdminCanAddToCart="superAdminCanAddToCart"
            >
            <!-- SLOT: ACTION BUTTONS -->
            <template slot="action-buttons">

             <div class="w-full" v-if="!isInCartTest(item._id) || isProductScript(item) || isProductBundle(item)">

                <div @click="cartButtonClicked(item)"
                      v-if="(item.canBuy || superAdminCanAddToCart) && item.isAvailable"
                      class="
                        item-view-secondary-action-btn
                        bg-primary
                        w-full
                        rounded-lg
                        py-3
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                        text-base
                        font-base
                      "> Add to Cart
                </div>
              </div>

              <div v-if="!item.canBuy && !superAdminCanAddToCart" class="w-full">
                <div class="
                        item-view-secondary-action-btn
                        bg-primary
                        w-full
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      " @click="learnMore(item.supplier.supplierOnboardingLink)"
                      v-if="item.supplier.supplierOnboardingLink"
                      >
                  <span class="text-base font-base">Learn More</span>
                </div>
                <div class="
                        item-view-secondary-action-btn
                        bg-primary
                        w-full
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      " @click="popupActive = true"
                      v-else
                      >
                  <span class="text-base font-base">Contact Fresh</span>
                </div>
              </div>
              <div v-if="!item.isAvailable && item.canBuy" class="w-full">
                <div class="
                        item-view-secondary-action-btn
                        bg-gray-400
                        w-full
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      ">
                  <span class="text-sm font-base">Out of Stock</span>
                </div>
              </div>
            </template>
          </item-list-view>
        </div>
        <!-- <p class="col-span-5 mb-base w-full md:w-1/2 lg:w-1/3 md:p-2 lg:p-2" v-if="paginatedItems.length <= 0">
          No products found!
        </p> -->
      </div>

      <div v-else-if="paginatedItems.length <= 0 && !isLoading" class="w-full flex justify-center">
        <div class=" text-center">
          <img style="width: 150px;" src="../../../assets/images/no-product-found.png"/>
          <h2>
            Sorry, no products found!
          </h2>
        </div>
      </div>
    </div>


    </div>
      </div>

      <vs-popup class="holamundo" title="Supplier Account Required" :active.sync="popupActive">
        <div class="flex flex-no-wrap">
          <div class="w-1/6 flex items-center justify-center">
            <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
          </div>
          <div class="w-5/6">
            <ContactFresh :email="contactEmail" :number="contactNumber" />
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <vs-button class="mr-4 mb-4" @click="popupActive = false">
            I understand
          </vs-button>
        </div>
      </vs-popup>

      <!-- PAGINATION -->
      <paginate class="pagination bg-transparent mt-10 mb-6"
         v-if="totalPages > 0"
         v-model="currentPage"
         :page-count="totalPages"
         :click-handler="goToPage"
         :prev-text="'<'"
         :next-text="'>'"
         :page-range="4"
      />

    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import _ from "lodash";
import ClinicSwap from "../../components/shop/clinic.vue";
import LDClient from "launchdarkly-js-client-sdk";
import ComingSoon from "../../clinic/ClinicShopComingSoon.vue";
import Navbar from "../../components/shop/Navbar.vue";
import ContactFresh from "../../components/ContactFresh"
import SideBarFilters from "../../components/shop/SideBarFilters.vue"

export default {
  name: "Category",
  inject: ["getRoleRouteName"],
  components: {
    ItemGridView: () =>
      import("@/views/components/order-center/ItemGridView.vue"),
    ItemListView: () => import("./ItemListView.vue"),
    ClinicSwap,
    ComingSoon,
    Navbar,
    ContactFresh,
    SideBarFilters,
  },
  data() {
    return {
      contactEmail: this.contactFresh('email'),
      contactNumber: this.contactFresh('number'),
      slug: "",
      value: [],
      jsonCategories: [],
      jsonCategoriesSlug: [],
      jsonChildCategoriesSlug: [],
      jsonCategoryWithSubCat: [],
      jsonCategoryName: [],
      jsonCategoryDescription: [],
      jsonCategoryDesktopImg: [],
      jsonCategoryMobileImg: [],
      categoryExists: true,
      parentCategory: '',
      grandParentCategory: '',
      subCategories: [],
      brands: [],
      selectedFilters: [],
      hasOrganization: false,
      showShop: false,
      categoryDetails: {},
      popupActive: false,
      filterParams: {
        categoriesOptions: [],
        brandId: [],
        vendorOptions: [],
        sortBy: "order_asc",
        search: "",
        page: 1,
        limit: 12,
      },
      limitOptions: [25, 50, 100],
      sortItems: [
        { value: "order_asc", label: "Sort by featured", id: 1, },
        { value: "price_asc", label: "Lowest Price", id: 2, },
        { value: "price_desc", label: "Highest Price", id: 3, },
      ],
      filteredItems: [],
      productList: [],
      itemsPerPage: 20,
      currentPage: this.$route.query.page || 1,
      showSidebar: false,
      alertBanner: [],
      learnMorePopupActive: false,
      selectedBanner: {},
      isMobileView: null,
      selectedSortBy: 1,
      sortBy: [{
        text: 'Featured',
        value: 1
      },
      {
        text: 'Others',
        value: 2
      }
      ],
      userType: "",
      userTypeAdmin: undefined,
      role: "",
      canAdd: false,
      isLoading: true,
      productOrders: [],
    }
  },
  computed: {
    populateBreadcrumbs () {
      /** get base url */
      let owner = this.$route.path.split('/')[1]
      if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin")
        owner = 'super-admin/store'

      const searchUrl = `/${owner}/shop`;
      return [
        { title: 'Home', url: searchUrl },
        ...(this.grandParentCategory && this.grandParentCategory.name
          ? [
              {
                title: this.grandParentCategory.name,
                url: `${searchUrl}/category/${this.grandParentCategory.slug}?page=1`,
              },
            ]
          : []),
        ...(this.parentCategory && this.parentCategory.name
          ? [
              {
                title: this.parentCategory.name,
                url: `${searchUrl}/category/${this.parentCategory.slug}?page=1`,
              },
            ]
          : []),
        {
          title:
            this.jsonCategoryName.length > 0
              ? this.jsonCategoryName
              : this.categoryDetails.name,
          active: true,
        },
      ]
    },
    superAdminCanAddToCart() {
      return (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") && !!this.canAdd
    },
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.learnMorePopupActive;
      }
      return true
    },
    paginatedItems() {
      //if current page is undefined let assigne zero instead to prevent startIndex to become NaN
      const startIndex = this.currentPage == undefined ? 0 : (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return _.slice(this.filteredItems, startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    getCartInfo() {
      return (itemId) => this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {

    ...mapActions("ecommerce", ["fetchFilterData", "getCartItemsFromApi", "productFilterListing", "getJsonSettings", "getProductOrderByCategory"]),
    ...mapActions("appUser", ["fetchAppUserIndex"]),
    ...mapActions("storeProducts", ["fetchStoreLimitation"]),
    ...mapActions("category", ["getSubCategoryList", "getCategoryDetailsBySlug"]),
    ...mapActions("brand", ["getBrandList"]),
    ...mapActions("admin", ["getSettingsByKey"]),

    learnMore(url){
      window.open(url)
    },
    isProductScript(item) {
      return item && item.type === "script";
    },
    isProductBundle(item) {
      return item && item.type === "bundle";
    },
    async fetchCanAddSettingKey() {
      try {
        const { data } = await this.getSettingsByKey("superAdminCanAdd")

        if (data.data) this.canAdd = data.data.value === "yes"
      } catch (err) {
        console.log(' ----> error fetching can-add setting key ')
        console.log(err)
      }
    },
    async fetchProductOrderByCategory(categoryId) {
      try {
        const res = await this.getProductOrderByCategory(categoryId)
        this.productOrders = res.data.data.productsOrder
      } catch (err) {
        console.log(err)
      }
    },
    contactFresh(contact) {
      if (process.env.VUE_APP_REGION !== 'AU') {
        if (contact === 'email') return this.contactEmail = "hello@freshclinics.com"
        if (contact === 'number') return this.contactNumber = "+1 888-925-1462"
      }

      if (contact === 'email') return this.contactEmail = "info@freshclinics.com.au"
      if (contact === 'number') return this.contactNumber = "1300 375 646"
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    clearSelectedCategory() {
      this.productOrders = []
      this.subCategories = this.subCategories.map(item => ({ ...item, isSelected: false }));
      this.brands = this.brands.map(item => ({ ...item, isSelected: false }));
      this.filteredItems = this.productList
      this.selectedFilters = [];
      this.goToPage(1)
    },
    onSubcategoryClose(item) {
      if(item.type === 'category'){
        this.subCategories[item.index].isSelected = !this.subCategories[item.index].isSelected;
      }
      else{
        this.brands[item.index].isSelected = !this.brands[item.index].isSelected;
      }
      //remove item
      const index = this.selectedFilters.findIndex(filter => (filter.name === item.name) && (filter.index === item.index))
      this.selectedFilters.splice(index, 1)
      if (this.selectedFilters.length > 0) {
        this.filterProducts()
      } else {
        this.filteredItems = this.productList
      }

    },
    handleSelectBanner(details) {
      this.selectedBanner = {...details}
      this.learnMorePopupActive = true;
    },
    filterProducts() {

      const selectedSubCat = _.filter(this.subCategories, ['isSelected', true]).map(sc => (sc._id));
      const selectedBrands = _.filter(this.brands, ['isSelected', true]).map(sc => (sc._id));

      /** select all products with in the selected filters */
      this.filteredItems = _.filter(this.productList, function (item) {
        const commonItems = _.intersection(item.categoryId, selectedSubCat)
        let brandId = []
        if(item.brand.length > 0){
          brandId = [item.brand[0]._id]
        }
        const brandCommonItems = _.intersection(brandId, selectedBrands)

        //when only subcategory is selected
        if(selectedSubCat.length > 0 && selectedBrands.length === 0 ){
          return commonItems.length > 0;
        }

        //when only brand is selected
        if((selectedBrands.length > 0) && (selectedSubCat.length === 0 )){
          return brandCommonItems.length;
        }

        //when both subcategory and brand is selected
        if((selectedSubCat.length) > 0 &&  (selectedBrands.length > 0)){
          return commonItems.length > 0 && brandCommonItems.length > 0;
        }
      })

      this.goToPage(1)
    },

    goToPage(pageNumber) {
      let routerName = "";

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routerName = "SuperAdminOrderCenterCategoryPage"

      } else {
        if (this.role === "Org Owner") {
          routerName = "OrgOwnerOrderCenterCategoryPage"
        }
        else {
          if (this.userType === "doctor") {
            routerName = "DoctorOrderCenterCategoryPage"
          }
          if (this.userType === "nurse") {
            routerName = "NurseOrderCenterCategoryPage"
          }
          else {
            routerName = "BasicOrderCenterCategoryPage"
          }
        }
      }

      this.$router.push({ name: routerName, query: { page: pageNumber } });
      this.currentPage = pageNumber;
    },
    onFilterClick(item, index, type){
      let data = {
        index: index,
        type: type,
      }
      if(type === "brand"){
        data.name = item.brandName
        if(this.brands[index].isSelected) return this.onSubcategoryClose(data);
        this.brands[index].isSelected = !this.brands[index].isSelected;
        this.selectedFilters.push(data)
      }
      else{
        data.name = item.name
        if(this.subCategories[index].isSelected) return this.onSubcategoryClose(data);
        this.subCategories[index].isSelected = !this.subCategories[index].isSelected;
        this.selectedFilters.push(data)
      }

    },
    quantityChange(data) {
      if (this.filteredItems[data.index].type !== 'basic' || this.filteredItems[data.index].tc) {
        this.$router.push(
          {
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: this.filteredItems[data.index]._id,
            },
          }).catch(() => { });
        return false;
      }
      this.filteredItems[data.index].selectedQuantity = data.quantity;
    },
    async getStoreLimitiation() {
      const { data } = await this.fetchStoreLimitation();
      this.AllLimitations = data.data.docs;
    },
    async getCategoryDetails() {
      try {
        const res = await this.getCategoryDetailsBySlug(this.$route.params.slug);
        this.categoryDetails = res.data.data
        if(res.data.data.subCategoryDetails.length > 0){
            this.fetchProductOrderByCategory(res.data.data._id)
          }
          else{
            this.productOrders = []
          }
      } catch (error) {
        console.log(error)
      }
    },
    async getAllBrands(){
      try{
        const res = await this.getBrandList();
        this.brands = res.data.data.docs.map(item => ({ ...item, isSelected: false }));
      }
      catch(err){
        console.log(err)
      }
    },
    async getProductsStore() {
      try {
        this.isLoading = true
        this.$vs.loading({
          container: this.$refs.loadableDiv
        })
        const res = await this.productFilterListing(this.filterParams)
        this.categoryExists = res.data.data.categories.length > 0
        this.filteredItems = [];
        this.productList = this.categoryExists ? res.data.data.products : [];
        this.filteredItems = this.categoryExists ? res.data.data.products : [];
        if(this.productOrders.length > 0){
          this.filteredItems = this.filteredItems.map(product => {
            const tempProduct = {...product}
            const tempOrder = this.productOrders.filter((order) => product._id === order.productId)
            tempProduct.orderId = tempOrder.length > 0 ? tempOrder[0].orderId : null

            return tempProduct
          })
        }
        this.filteredItems = _.sortBy(this.filteredItems , [function(o) { return o.orderId; }])
        this.subCategories = res.data.data.subCategories.map(item => ({ ...item, isSelected: false }));
        let brand = [];

        this.filteredItems.map(item => {
          if(item.brand.length > 0){
            brand = [...brand, ...item.brand]
          }
        })

        this.brands = _.uniqBy(brand, 'brandName')

        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
        this.isLoading = false

      if (this.$route.query.page) {
          this.goToPage(this.$route.query.page);
        } else {
          this.goToPage(1);
        }

      } catch (err) {
        console.log(err);
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
        this.isLoading = false
      }
    },
    async cartButtonClicked(item) {
      if (item.tcc || item.type === 'bundle' || item.type === 'lead' || item.type === 'script') {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: item._id,
            },
          })
          .catch(() => { });
        return false;
      }

      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      const totalCartAndSelectedQty = (cartQuantity + item.selectedQuantity)
      if (item.inventory.maxQuantity !== null && item.inventory.maxQuantity !== 0) {
        if (item.selectedQuantity > item.inventory.maxQuantity || totalCartAndSelectedQty > item.inventory.maxQuantity) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }

      this.additemInCart(item, 'increment');
    },
    additemInCart(data, type) {
      let minQty = 1;
      if(data.inventory.requiredQuantity > 1) {
        minQty = data.inventory.requiredQuantity
      }
      let item = {data: data, type: type, minQty: minQty}

      this.$vs.loading();

      item.data.shipping.shippingFee = item.data.supplier.deliveryDetails ? item.data.supplier.deliveryDetails.deliveryFee : 0;

      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if(response.status === 200 && response.data.message === 'success') {
          this.getCartItemsFromApi()
          this.$vs.notify({
            color: 'success',
            title: 'Product added',
            text: 'Product was successfully added to your cart.'
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }

        this.$vs.loading.close();
      }).catch((err) => {
        this.$vs.loading.close();
        const supplierErrMsg = "Sorry! You need to setup a supplier account to purchase this product."
          if (err.data && err.data.message && err.data.message === supplierErrMsg) {
            this.paginatedItems.find(p => p._id.toString() === data._id.toString()).canBuy = false;
            this.$vs.notify({
              color: 'danger',
              title: 'Add to cart failed',
              text: supplierErrMsg,
              time: 5000,
            });
          }
          console.log("ERR: ", err);
      });
    },
    findJsonCategoriesBySlug(categories, targetSlug) {
      const category = _.find(categories, { 'slug': targetSlug });

      if (category) {
        return category;
      }

      for (const subCategory of categories) {
        const nestedCategoryName = this.findJsonCategoriesBySlug(_.get(subCategory, 'categories', []), targetSlug);
        if (nestedCategoryName) {
          return nestedCategoryName;
        }
      }

      return null;
    },
    async getJsonCategory(slug) {
      this.$vs.loading({
          container: this.$refs.loadableDiv
        })
      try {

        const res = await this.getJsonSettings({ slug: "navigation" })
        const jsonCatgry = this.findJsonCategoriesBySlug(res.data.data.product, slug)
        this.jsonCategories = jsonCatgry ? jsonCatgry.categories : []
        this.jsonCategoriesSlug = this.jsonCategories.map(category => category.slug);
        this.jsonCategoryName = jsonCatgry.name
        this.jsonCategoryDescription = jsonCatgry.description
        this.jsonCategoryDesktopImg = jsonCatgry.image
        this.jsonCategoryMobileImg = jsonCatgry.image
        const productCategories = res.data.data.product;
        this.jsonCategoryWithSubCat = this.getCategoriesWithSubcategories(productCategories)
        this.parentCategory = await this.parentJsonCategory(slug,productCategories)
        this.jsonChildCategoriesSlug = this.findChildrenSlugs(productCategories,this.jsonCategoriesSlug);
        this.grandParentCategory =this.parentCategory ? this.parentJsonCategory(this.parentCategory.slug,productCategories) : null
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
      } catch (error) {
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
        console.log(error)
      }
    },
    parentJsonCategory(slug, categories) {
      for (const category of categories) {
        if (category.categories && category.categories.length > 0) {
          const foundCategory = category.categories.find(
            (subCategory) => subCategory.slug === slug
          );
          if (foundCategory) {
            return category;
          }
          const recursiveSearch = this.parentJsonCategory(
            slug,
            category.categories
          );
          if (recursiveSearch) {
            return recursiveSearch;
          }
        }
      }
      return null;
    },
    onCategoryClick(slug) {

      if (slug !== '' || !isUndefined(slug)) {
        const userType = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        ).userType;
        const role = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
        );

        let routerName = "";
        if (role === "Org Owner") {
          routerName = "OrgOwnerOrderCenterCategoryPage"
        }
        else {
          switch (userType) {
        case "doctor":
          routerName = "DoctorOrderCenterCategoryPage";
          break;
        case "nurse":
          routerName = "NurseOrderCenterCategoryPage";
          break;
        case "superAdmin":
          routerName = "SuperAdminOrderCenterCategoryPage";
          break;
        default:
          routerName = "BasicOrderCenterCategoryPage";
      }
        }

        this.$router.push({
          name: routerName,
          params: {
            slug: slug
          },
          query: {
            page: 1
          }
        })
        
      }
    },
     findChildrenSlugs(categories, slugArray) {
      let childrenSlugs = [];
      function findSlugs(categories, slugs) {
        categories.forEach(category => {
          if (slugs.includes(category.slug)) {
            category.categories.forEach(child => {
              childrenSlugs.push(child.slug);
            });
          } else {
            findSlugs(category.categories, slugs);
          }
        });
      }
      findSlugs(categories, slugArray);
      return childrenSlugs;
    },
    getCategoriesWithSubcategories(categories) {
    const slugs = [];
    const findCategories = (categories) => {
      categories.forEach(category => {
        if (category.categories.length > 0) {
          slugs.push(category.slug);
          findCategories(category.categories);
        }
      });
    };
    categories.forEach(category => {
      if (category.categories.length > 0) {
        slugs.push(category.slug);
        findCategories(category.categories);
      }
    });
    return slugs;
  }
  },
  beforeMount: async function () {
    const { email } = this.$store.state.AppActiveUser;
    const userIndex = await this.fetchAppUserIndex();
    this.getStoreLimitiation()
    const key = userIndex.data.data.count;
    const user = {
      email,
      key,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);

    this.hasOrganization = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
  },
  watch: {
    //If filtered items change, update the paginated items
    // subCategories: {
    //   handler(newArr, oldArr) {
    //     /** only filter if there is a slected value */
    //     if (_.some(newArr, (obj) => obj.isSelected)) {
    //       this.filterProducts()
    //     }
    //   },
    //   deep: true
    // },
    selectedFilters: function (newVal, oldVal) {
        if(newVal.length > 0){
          this.filterProducts()
        }
    },
    "$route.params.slug": async function (newVal, oldVal) {
      this.clearSelectedCategory()
      if(this.$route.query.page){
        this.goToPage(this.$route.query.page)
      }else{
        this.goToPage(1)
      }

      if (oldVal !== newVal) {
        if (this.$route.params.slug) {
          this.selectedFilters = [];
          this.getCategoryDetails()
          await this.getJsonCategory(this.$route.params.slug)
          this.filterParams.categoriesOptions =
            this.jsonCategoriesSlug.length > 0
              ? [
                  ...this.jsonCategoriesSlug,
                  ...this.jsonChildCategoriesSlug,
                ]
              : [this.$route.params.slug];
          if (this.jsonCategoriesSlug.length > 0) this.filterParams.categoriesOptions.push(this.$route.params.slug)
          this.getProductsStore();
        }
        else {
          this.$router.push("shop")
        }
      }
    },
    "$store.state.clinicId": async function (newVal, oldVal) {
      this.clearSelectedCategory()
      if(this.$route.query.page){
        this.goToPage(this.$route.query.page)
      }else{
        this.goToPage(1)
      }

      if (oldVal !== newVal) {
        if (this.$route.params.slug) {
          this.selectedFilters = [];
          this.getCategoryDetails()
          await this.getJsonCategory(this.$route.params.slug)
          this.filterParams.categoriesOptions =
            this.jsonCategoriesSlug.length > 0
              ? [
                  ...this.jsonCategoriesSlug,
                  ...this.jsonChildCategoriesSlug,
                ]
              : [this.$route.params.slug];
          if (this.jsonCategoriesSlug.length > 0) this.filterParams.categoriesOptions.push(this.$route.params.slug)
          this.getProductsStore();
        }
        else {
          this.$router.push("shop")
        }
      }
    },
    "$store.state.showStore": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.showShop = this.$store.state.showStore;
      }
    },
    "$route.query": function (newVal, oldVal) {
  if (oldVal !== newVal) {
    this.currentPage = newVal.page
      }
    }
  },
  async created() {
    this.showShop = this.$store.state.showStore;
    this.slug = this.$route.params.slug
    this.getCategoryDetails(this.slug)
    await this.getJsonCategory(this.$route.params.slug)
    this.filterParams.categoriesOptions =
      this.jsonCategoriesSlug.length > 0
        ? [...this.jsonCategoriesSlug, ...this.jsonChildCategoriesSlug]
        : [this.$route.params.slug];
    if (this.jsonCategoriesSlug.length > 0) this.filterParams.categoriesOptions.push(this.slug)
    this.getProductsStore();
    // this.getAllBrands();
    this.userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    ).userType;
    this.role = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
    );
    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
    this.showShop = this.userTypeAdmin === 'superAdmin' ? true : this.$store.state.showStore;

    if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin") this.fetchCanAddSettingKey()
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  background-color: white;
}

.select-filter-dd {
  background-color: #074230;
  width: 74px;
  color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  letter-spacing: 2px;
  height: 34px;

  i,
  span {
    color: #eee;
  }
}

.cat-filter {
  border-bottom: solid thin #f2f2f2;
}

.banner {
  background: #f2f2f2;
}

.themed-text {
  color: #074230;
}

.label {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 24px;
  color: #0E4333;

}

.category-text {
  color: #2d2d2d;
  font-weight: lighter;
}

[dir] .con-select .vs-select--input {
  border: 0 !important;
  padding: 13px;
  font-size: 1.1rem;
}

.hover-text-red-600:hover {
  color: #e53e3e;
}

.tag {
  color: #074230;
  background-color: rgba(222,239,234, .34);
}

.products-sidebar .vs-sidebar--header{
  background-color: #074230;
}

.products-sidebar .vs-sidebar-primary {
  max-width: 100%;
}

.products-sidebar .vs-sidebar--footer {
  height: 42px;
  background-color: #84CDB8;
}
.footer-container {
  height: 42px;

}

.items-wrapper  {
  > div {
    border-left: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    &:first-of-type,
    &:nth-of-type(2) {
      border-top: 1px solid #eaeaea;
    }

    &:nth-of-type(even) {
      border-left: none;
      border-right: 1px solid #eaeaea;
    }

    &:nth-of-type(odd) {
      border-right: 1px solid #eaeaea;
    }
  }
}

@media (min-width: 992px) {
  .items-wrapper > div{
    border: 1px solid #eaeaea !important;
    border-radius: .3rem;
  }

}
@media (max-width: 991px) {
  .router-view {
    padding: 2rem 0 !important;
  }
}

.learnMorePopup .vs-popup {
  width: 50%;
}


// [dir] .navbar-floating .router-content {
//     margin-top: 15.5rem ;
//     @media (max-width: 768px) {
//       margin-top: 8.5rem ;
//     }
// }
</style>
